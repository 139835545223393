import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

const endpoint = '/api/communication';

export class _Event {
  public static fromJson(json: Object): _Event {
    return new _Event(
      json['promotion'],
      json['type'],
      json['title'],
      json['created'],
      json['start'],
      json['end']
    )
  }

  constructor(
    public _id?: string,
    public promotion: string = '',
    public type: string = 'Divers',
    public title: string = '',
    public created: any = Date.now(),
    public start: any = Date.now(),
    public end: any = Date.now()
  ) {}
}

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class EventService {

  constructor(
    private http: HttpClient
  ) { }

  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if(err.error instanceof ErrorEvent) {
      errorMessage = `An error occured: ${err.error.message}`;
    } else {
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

  /**
   * Get events for a promotion
   * @param {String} id - user's prom
   * @return {Array<_Event>}
   */
  getEvents(id: String) {
    return this.http.get<_Event[]>(`${endpoint}/events/${id}`).pipe(
      catchError(this.handleError)
    );
  }
}
