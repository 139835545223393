import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { AdvertService, _Advert } from '../shared/services/advert/advert.service';
import { EventService } from '../shared/services/event/event.service';
import { NewsService, _News } from '../shared/services/news/news.service';
import { _Event } from '../shared/services/tools/tools.service';

import * as _ from 'lodash';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  public user:any;
  private subscription: Subscription;
  public adverts: Array<_Advert> = [];
  public events: Array<_Event> = [];
  public news: Array<_News> = [];
  public today: Date = new Date();

  constructor(
    private Auth: AuthService,
    private Adv: AdvertService,
    private Evt: EventService,
    private News: NewsService
  ) { }

  ngOnInit() {
    this.subscription = this.Auth.getUserBehavior().subscribe(res => {
      this.user = res;
      if(res && res.profil) {
        this.Adv.getAdvert(this.user.profil.promotion).subscribe(res => {
          this.adverts = _.orderBy(res, 'createdOn', 'desc');
        });
  
        this.Evt.getEvents(this.user.profil.promotion).subscribe(res => {
          this.events = res;
        });
  
        this.News.getNews().subscribe(res => {
          this.news = _.orderBy(res, 'limit', 'asc');
        });
      }
      
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
