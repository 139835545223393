import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

const endpoint = '/api/communication';

export class _News {
  public static fromJson(json: Object): _News {
    return new _News(
      json['type'],
      json['createdOne'],
      json['limit'],
      json['text'],
      json['link']
    )
  }

  constructor(
    public _id?: String,
    public type: String = '',
    public createdOn: Number = Date.now(),
    public limit: Number = Date.now(),
    public text: String = '',
    public link: String = ''
  ) {}
}

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class NewsService {

  constructor(
    private http: HttpClient
  ) { }

  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if(err.error instanceof ErrorEvent) {
      errorMessage = `An error occured: ${err.error.message}`;
    } else {
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

    /**
   * Get all News
   * @return {Array<_News>}
   */
     getNews() {
      return this.http.get<_News[]>(`${endpoint}/news`).pipe(
        catchError(this.handleError)
      );
    }
}
