import { trigger, transition, style, animate } from '@angular/animations';
import { Component, HostListener, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import * as QuillNamespace from 'quill';
import * as _ from 'lodash';
let Quill: any = QuillNamespace;

@Component({
  selector: 'app-diapo',
  templateUrl: './diapo.component.html',
  styleUrls: ['./diapo.component.scss']
})
export class DiapoComponent implements OnInit, OnChanges {

  @Input() slideTab;
  @HostListener('document:keydown', ['$event']) handleKeyboardEvent(event: KeyboardEvent) {
    switch (event.key) {
      case 'ArrowRight': {
        this.index = this.index != this.maxLength ? this.index + 1 : this.maxLength;
        break;
      }
      case 'ArrowLeft': {
        this.index = this.index != 0 ? this.index - 1 : 0;
        break;
      }
      default:
        // console.log('Not binding key');
        break;
    }
  }

  public index: number = 0;
  public maxLength: number;
  public pagination: any;
  public glossaire: any = [];
  public showGlossaire: boolean = true;
  public activGlossaire: boolean = true;
  public title: string;

  constructor() { }

  ngOnInit() {
    this.maxLength = this.slideTab.diapos.length - 1;
    this.setGlossaire();
  }
  
  ngOnChanges(changes: SimpleChanges) {
    console.log(changes)
    if(changes.slideTab.currentValue != changes.slideTab.previousValue) {
      this.title = this.slideTab.title;
      this.index = 0;
      this.maxLength = this.slideTab.diapos.length - 1;
      this.pagination = Array(this.maxLength + 1);
      if(this.slideTab.diapos.length == 1) {
        this.activGlossaire = false;
      } else {
        this.setGlossaire();
      }
    }
  }

  onClickIndicator(axe) {

    if(axe == 'add' && this.index != this.maxLength) {
      this.index++;
    } else if(axe == 'cut' && this.index != 0) {
      this.index--;
    } else {
      console.log('Unknown axe value or Index limitation');
    }
  }

  setGlossaire() {
    this.glossaire = [];
    if(this.slideTab.diapos.length == 1 ) {
      this.glossaire = [];
    } else {
      for (let i = 0; i < this.slideTab.diapos.length; i++) {
        let alreadySet = _.findIndex(this.glossaire, (o: any) => {return o.title == this.slideTab.diapos[i].title});
        if(alreadySet == -1) this.glossaire.push({index: i, title: this.slideTab.diapos[i].title});
        
      }
    }
  }

  goToDiapo(pos) {
    this.index = pos;
  }
}
