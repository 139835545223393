import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    public router: Router
  ) {}

    canActivate() {
      const user = (<any>window).user;
      let storedUserBehavior = JSON.parse(localStorage.getItem('StoredUserBehavior'));
      if (user || storedUserBehavior)  return true;

      // not logged in so redirect to login page with the return url
      this.router.navigate(['/auth/login']);
      return false;
    }
}
