import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';


const endpoint = '/api/profil';


export class _Profil {
  public static fromJson(json: Object): _Profil {
    return new _Profil(
      json['user'],
      json['mail'],
      json['organism'],
      json['birthdate'],
      json['promotion'],
      json['background'],
      json['already_known'],
      json['level']
    );
  }
  constructor(
    public _id?: String,
    public user: String = '',
    public mail: String = '',
    public organism: String= '',
    public birthdate: String = '',
    public promotion: String = '',
    public background: String = '',
    public already_known: Array<String> = [],
    public level: Number = 0
  ) {}
}


const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class ProfilService {

  constructor(
    private http: HttpClient
  ) { }


  public language: Array<String> = ['HTML / CSS', 'Javascript', 'PHP', 'Angular', 'Symfony', 'C / C++', 'Java', 'Autres'];

  public level: Array<String> = ['Débutant', 'Habitué', 'Cours déjà lu', 'Codeur débutant', 'Coder initié', 'Codeur en perfectionnement'];

  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if(err.error instanceof ErrorEvent) {
      errorMessage = `An error occured: ${err.error.message}`;
    } else {
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

  /**
   * Get my Profil
   * @param {String} id - user's id
   * @return {_Profil}
   */
  get(id: string) {
    return this.http.get<_Profil>(`${endpoint}/${id}`).pipe(
      catchError(this.handleError)
    )
  }

  /**
   * Update my Profil
   * @param {_Profil} pfl - user's profil
   */
  update(pfl: _Profil) {
    Reflect.deleteProperty(pfl, '__v');
    return this.http.put<_Profil>(`${endpoint}/${pfl._id}`, pfl, httpOptions).pipe(
      catchError(this.handleError)
    );
  }

}
