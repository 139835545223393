import { Component, OnInit, ChangeDetectionStrategy, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CalendarView, CalendarEvent } from 'angular-calendar';
import { setHours, setMinutes } from 'date-fns';
import { AuthService } from '../../../auth/auth.service';
import { _Event } from '../../services/tools/tools.service';
import * as _ from 'lodash';

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};

@Component({
  selector: 'calendar',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class Calendar implements OnInit, OnChanges {

  @Input() events;
  @Input() legend;
  @Input() widget;
  public view: CalendarView = CalendarView.Month;
  public calendarV: any = 'Month';
  public viewDate: Date = new Date();

  public eventFormat: any[] = [];
  public monthEvent: any[] = [];
  public arrayTypeEvent: any[] = [];
  public year: any[] = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
  constructor(
  ) { }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.events.currentValue != changes.events.previousValue) {
      this.eventFormat = [];
      // console.log(this.events)
      if(this.events && this.events.length > 0) {
        this.events.forEach(elt => {
          // console.log(elt)
          this.eventFormat.push(this.setEvent(elt))
          this.orderEvent(this.eventFormat, this.monthEvent, this.arrayTypeEvent)
          this.monthEvent = _.orderBy(this.monthEvent, ['month', 'number'], ['asc', 'desc'])
          this.monthEvent = _.uniqBy(this.monthEvent, 'month')
        });
      }
    }
  }

  dayClicked(e) {
    this.view = CalendarView.Day;
    this.viewDate = new Date(e.day.date);
    this.calendarV = 'Day';
  }

  monthCliked(e) {
    let today = new Date();
    this.view = CalendarView.Month;
    this.viewDate = new Date(today.getFullYear(), e);
  }

  viewChanged(view) {
    if(view == 'Month') {
       this.view = CalendarView.Month;
       this.calendarV = 'Month';
    }

    if(view == 'Day')  {
      this.view = CalendarView.Day;
      this.calendarV = 'Day';
    }
  }


  setEvent(evt) {
    let tmpColor: any;
    switch (evt.type) {
      case 'Cours':
        tmpColor = colors.yellow;
        break;
      case 'Examen':
        tmpColor = colors.red;
        break;
      case 'Divers':
        tmpColor = colors.blue
        break;
    
      default:
        console.log('Unknow type event');
        break;
    }

    let evtStart = new Date(evt.start);
    let evtEnd = evt.end ? new Date(evt.end) : null;
    let evtAllDay = evt.end ? false : true;

    return new _Event(evt.title, evtStart, evtEnd, evtAllDay, tmpColor);
  }

  orderEvent(evts, monthEvent, arrayTypeEvent) {
    let today = new Date();
    evts.forEach(elt => {
      let filter = _.filter(evts, (o) => {
        return o.start.getMonth() == elt.start.getMonth() && elt.start.getMonth() >= today.getMonth()
      });
      if(filter && filter.length > 0 && filter[0].start.getMonth() >= today.getMonth()) {
        let number = filter.length;
        let month = filter[0].start.getMonth();
        monthEvent.push({month, number})
      }
    });

    arrayTypeEvent[0] = [];
    arrayTypeEvent[0] = _.filter(this.events, (o) => {
      let tmp = new Date(o.start);
      return o.type == 'Cours' && tmp.getMonth() >= today.getMonth();
    });

    arrayTypeEvent[1] = [];
    arrayTypeEvent[1] = _.filter(this.events, (o) => {
      let tmp = new Date(o.start);
      return o.type == 'Examen' && tmp.getMonth() >= today.getMonth();
    });

    arrayTypeEvent[2] = [];
    arrayTypeEvent[2] = _.filter(this.events, (o) => {
      let tmp = new Date(o.start);
      return o.type == 'Divers' && tmp.getMonth() >= today.getMonth();
    });
  }
}
