import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth-guard.service';
import { HomeComponent } from './home/home.component';
import { ProfilComponent } from './profil/profil.component';
import { SettingsComponent } from './settings/settings.component';

import { P404Component } from './error/404.component';
import { P500Component } from './error/500.component';
import { NotauthorizedComponent } from './error/notauthorized.component';
import { CalendarComponent } from './calendar/calendar.component';
import { ContactComponent } from './contact/contact.component';
import { MentionComponent } from './mention/mention.component';

const routes: Routes = [
  {
  path: '',
  redirectTo: '/home',
  pathMatch: 'full'
},
{
  path: 'home',
  component: HomeComponent,  
}, {
  path: 'auth',
  loadChildren: 'app/auth/auth.module#AuthModule',
  canActivate: [AuthGuard]
}, { 
  path: 'support',
  loadChildren: 'app/support/support.module#SupportModule',
  canActivate: [AuthGuard]
},{
  path: 'settings',
  component: SettingsComponent,
  canActivate: [AuthGuard]
}, {
  path: 'profil',
  component: ProfilComponent,
  canActivate: [AuthGuard]
}, {
  path: 'calendar',
  component: CalendarComponent,
  canActivate: [AuthGuard]
}, {
  path: 'contact',
  component: ContactComponent
}, {
  path: 'admin',
  loadChildren: 'app/admin/admin.module#AdminModule',
  canActivate: [AuthGuard]
}, {
  path: 'notauthorized',
  component: NotauthorizedComponent
}, { 
  path: 'mentions-legales',
  component: MentionComponent
}, {
  path: '**',
  component: P404Component,
  data: {
    title: 'Page 404'
  }
}];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard],
  declarations: []
})

export class AppRoutingModule {}
