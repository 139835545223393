import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProfilService } from '../shared/services/profil/profil.service';
import * as _ from 'lodash';
import { AuthService } from '../auth/auth.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-profil',
  templateUrl: './profil.component.html',
  styleUrls: ['./profil.component.scss']
})
export class ProfilComponent implements OnInit, OnDestroy {
  public bsConfig: any;
  public maxDate: any;
  public birthdate: any;

  public profil;
  public user;
  public level: Array<String> = [];
  public lang: Array<String> = [];
  public showModif: Boolean = false;
  public known: Array<String> = [];
  public alertDismiss = [];

  public profilTime = false;

  public userSub: Subscription;

  constructor(
    private pfl: ProfilService,
    private Auth: AuthService
  ) {
    this.maxDate = new Date();
    this.maxDate = `${this.maxDate.getMonth() + 1}/${this.maxDate.getDate()}/${this.maxDate.getFullYear()}`;
   }

  ngOnInit() {
    this.bsConfig = {
      adaptivePosition: true,
      containerClass: 'theme-green',
      locale: 'fr'
    };

    this.level = this.pfl.level;
    this.lang = this.pfl.language;
    this.userSub = this.Auth.$userBehavior.subscribe(res => {
      this.profil = res ? res.profil : null;
      this.user = res ? res.user : null;
      this.profilTime = true;
    })
  }

  isKnown(lang: string) {
    let index = _.findIndex(this.known, (o) => { return o == lang});
    return index != -1;
  }

  setKnown(lang: string) {
    let index = _.findIndex(this.known, (o) => { return o == lang});
    if(index == -1) {
      this.known.push(lang);
    } else {
      this.known.splice(index, 1);
    }
  }

  onShowModif() {
    this.showModif = !this.showModif;
    this.known = this.profil.already_known;
    let tmp = this.profil.birthdate.split('/');
    this.birthdate = new Date(`${tmp[2]}-${tmp[1]}-${tmp[0]}`)
    this.maxDate = new Date(this.maxDate);
  }

  put() {
    this.profil.birthdate = this.birthdate.toLocaleDateString();
    this.pfl.update(this.profil).subscribe(res => {
      this.showModif = false;
      this.Auth.$userSource.next({user: this.user, profil: this.profil});
      this.Auth.$userBehavior.next({user: this.user, profil: this.profil});
    })
  }

  ngOnDestroy() {
    this.userSub.unsubscribe();
  }
}
