import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap';

import {AuthService} from '../auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['../auth.component.scss']
})
export class LoginComponent implements OnInit {
  public error: boolean = false;
  @ViewChild('confirmationMdp') public confirmationMdp: ModalDirective;

  constructor(private authService: AuthService, private router: Router) { }

  email: string;
  password: string;
  mailReinit: string;
  confirm: string;

  ngOnInit() {
    let storedUserBehavior = JSON.parse(localStorage.getItem('StoredUserBehavior'));
    if(storedUserBehavior) this.router.navigate(['/home']);
  }

  login(): void {
    this.authService.login(this.email, this.password)
    .subscribe(
      data => {
        this.error = false;
        sessionStorage.setItem('user', JSON.stringify(data));
        this.router.navigate(['/']);
      }, err => {
        this.error = true;
      }
    )
  }

  changePassword() {
    this.confirmationMdp.hide();
    this.authService.reinitPassword(this.mailReinit).subscribe(res => {
      this.confirm = res;
      this.mailReinit = null;
    })
  }

}
