import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { EventService } from '../shared/services/event/event.service';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit, OnDestroy {
  private subscription: Subscription;

  constructor(
    private Auth: AuthService,
    private Evt: EventService
  ) {}
  public events: any = [];

  ngOnInit() {
    this.subscription = this.Auth.getUserBehavior()
    .subscribe(res => {
      this.Evt.getEvents(res.profil.promotion).subscribe(res => {
        // console.log(res)
        this.events = res;
      });
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
