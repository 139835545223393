interface NavAttributes {
  [propName: string]: any;
}
interface NavWrapper {
  attributes: NavAttributes;
  element: string;
}
interface NavBadge {
  text: string;
  variant: string;
}
interface NavLabel {
  class?: string;
  variant: string;
}

export interface NavData {
  name?: string;
  url?: string;
  icon?: string;
  badge?: NavBadge;
  title?: boolean;
  children?: NavData[];
  variant?: string;
  attributes?: NavAttributes;
  divider?: boolean;
  class?: string;
  label?: NavLabel;
  wrapper?: NavWrapper;
}

export const rightBarItems: NavData[] = [

];

export const navItems: NavData[] = [
  {
    name: 'Home',
    url: '/',
    icon: 'icon-home'
  },
  {
    name: 'Support',
    icon: 'icon-layers',
    url: ' ',
    children: [
      {
        name: 'Cours',
        url: 'support/cours',
        icon: 'icon-screen-desktop'
      },
      {
        name: 'Exercices',
        url: 'support/exo',
        icon: 'icon-chemistry'
      },
      {
        name: 'Corrections',
        url: 'support/corrige',
        icon: 'icon-magic-wand'
      }
    ]
  },
  {
    name: 'Calendrier',
    icon: 'icon-calendar',
    url: '/calendar',
  },
  {
    name: 'Contact',
    icon: 'icon-bubbles',
    url: '/contact'
  },
  {
    name: 'Sikara',
    url: 'https://www.sikara.fr',
    icon: 'icon-doc',
    class: 'mt-auto',
    variant: 'success',
    attributes: { target: '_blank', rel: 'noopener' }
  }
];

export const navItemsNoProfil: NavData[] = [
  {
    name: 'Home',
    url: '/',
    icon: 'icon-home'
  },
  {
    name: 'Support',
    icon: 'icon-layers',
    url: ' ',
    children: []
  },
  {
    name: 'Outils',
    icon: 'icon-wrench',
    url: ' ',
    children: []
  },
  {
    name: 'Sikara',
    url: 'https://www.sikara.fr',
    icon: 'icon-doc',
    class: 'mt-auto',
    variant: 'success',
    attributes: { target: '_blank', rel: 'noopener' }
  }
]