import { Component, OnInit, Inject, Input, OnDestroy } from '@angular/core';
import { navItems, navItemsNoProfil } from '../_nav';
import { AuthService } from '../../auth/auth.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-leftbar',
  templateUrl: './leftbar.component.html',
  styleUrls: ['./leftbar.component.scss']
})
export class LeftbarComponent implements OnInit, OnDestroy {
  @Input() user: any = {};

  public navItems: any = navItems;

  public sidebarMinimized = true;
  public myUser: any;
  public userSub: Subscription;

  constructor(
    private Auth: AuthService,
    private router: Router
  ) {}

  ngOnInit() {
    this.userSub = this.Auth.$userSource.subscribe(res => {
      this.myUser = res.user;
      this.navItems = res.profil ? navItems : navItemsNoProfil;
    });
  }

  ngOnDestroy(): void {
    this.userSub.unsubscribe();
  }
}
