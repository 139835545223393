import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
@Injectable()
export class IdleTimer {

  constructor(
    private auth: AuthService
  ) {
    this.tracker();
    this.startInterval();
  }

  startInterval() {
    this.updateExpiredTime();
    let interval = setInterval(() => {
      const expiredTime = parseInt(localStorage.getItem('_expiredTime'));
      if(expiredTime < Date.now()) {
        clearInterval(interval);
        this.auth.signOut();
      }
    }, 1800 * 1000);
  }

  updateExpiredTime() {
    let tim =  Date.now() + 3600 * 1000;
    localStorage.setItem('_expiredTime', JSON.stringify(tim));
  }

  tracker() {
    window.addEventListener('mousemove', this.updateExpiredTime);
    window.addEventListener('scroll', this.updateExpiredTime);
    window.addEventListener('keydow', this.updateExpiredTime);
  }
}
