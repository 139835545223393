import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

interface EventColor {
  primary: string,
  secondary: string
}

// export interface CalendarEvent<MetaType = any> {
//   id?: string | number;
//   start: Date;
//   end?: Date;
//   title: string;
//   color?: EventColor;
//   actions?: EventAction[];
//   allDay?: boolean;
//   cssClass?: string;
//   resizable?: {
//     beforeStart?: boolean;
//     afterEnd?: boolean;
//   };
//   draggable?: boolean;
//   meta?: MetaType;
// }

const endpoint = '/api/contact';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

export class _Event {
  public static fromJson(json: Object): _Event {
    return new _Event(
      json['title'],
      json['start'],
      json['end'],
      json['allDay'],
      json['color'],
    )
  }

  constructor(
    public title: string,
    public start: Date,
    public end?: Date,
    public allDay?:boolean,
    public color?: EventColor
  ) {}
}


@Injectable({
  providedIn: 'root'
})
export class ToolsService {

  constructor(
    private http: HttpClient
  ) { }

  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if(err.error instanceof ErrorEvent) {
      errorMessage = `An error occured: ${err.error.message}`;
    } else {
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

  /**
   * Send mail from visitor
   * @param {string} name 
   * @param {string} forname
   * @param {string} mail
   * @param {string} subject
   * @param {string} message
   */
  sendMailVisitor(name, forname, mail, subject, message) {
    return this.http.post<any>(`${endpoint}/visitor`, {name, forname, mail, subject, message}, httpOptions).pipe(
      catchError(this.handleError)
    )
  }


  /**
   * Send mail from User
   * @param {string} fullname
   * @param {string} organism
   * @param {string} mail
   * @param {string} subject
   * @param {string} message
   */
  sendMail(fullname, organism, mail, subject, message) {
    return this.http.post<any>(`${endpoint}/`, {fullname, organism, mail, subject, message}, httpOptions).pipe(
      catchError(this.handleError)
    )
  }
}
