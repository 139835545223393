import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';

import 'rxjs/add/operator/do';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';

@Injectable()
export class CatchErrorInterceptor implements HttpInterceptor {
  constructor(
    private router : Router
  ) {}
  intercept(request : HttpRequest < any >, next : HttpHandler) : Observable < HttpEvent < any >> {

    return next
      .handle(request)
      .pipe(
        catchError((err: any) => {
          return new Observable<HttpEvent<any>>(obs => {
            if (err instanceof HttpErrorResponse) {
              if (err.status === 401) {
                window.localStorage.removeItem('AuthToken');
                window.localStorage.removeItem('StoredUserBehavior');
                this.router.navigate(['/auth/login']);
              }
              obs.error(err);
              obs.complete();
            }
          })
        })
      );
      // .do 
      //   ((event : HttpEvent < any >) => {}, (err : any) => {
      //     if (err instanceof HttpErrorResponse) {
      //       window.localStorage.removeItem('AuthToken');
      //       window.localStorage.removeItem('StoredUserBehavior');
      //       this.router.navigate(['/auth/login']);
      //       let text = (err.error && err.error.message) ? err.error.message : err.statusText;
      //       (<any>window).globalEvents.emit('open error dialog', text);
      //     }
      //   });

  }
}