import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';

import { TokenStorage } from './token.storage';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';


@Injectable()
export class AuthService {

  constructor(
    private http : HttpClient, 
    private token: TokenStorage,
    private router: Router
  ) { }

  setStored(auth: any, storeUser: boolean = false) {
    if(storeUser) {
       localStorage.setItem('StoredUserBehavior', JSON.stringify(auth));
    }
    this.$userBehavior.next(auth);
  }

  getUserBehavior() {
    let storedUserBehavior = JSON.parse(localStorage.getItem('StoredUserBehavior'));
    this.setStored(storedUserBehavior, false);
    return this.$userBehavior;
  }

  public $userSource = new Subject<any>();
  public $userBehavior = new BehaviorSubject<any>(null);

  login(email : string, password : string) : Observable <any> {
    return Observable.create(observer => {
      this.http.post('/api/auth/login', {
        email,
        password
      }).subscribe((data : any) => {
          observer.next({user: data.user, profil: data.profil});
          this.token.saveToken(data.token);
          this.setUser(data);
          observer.complete();
      },
      (err: any) => {
        observer.error(err)
      })
    });
  }

  register(fullname : string, email : string, password : string, repeatPassword : string) : Observable <any> {
    return Observable.create(observer => {
      this.http.post('/api/auth/register', {
        fullname,
        email,
        password,
        repeatPassword
      }).subscribe((data : any) => {
        observer.next({user: data.user});
        this.token.saveToken(data.token);
        this.setUser(data.user);
        observer.complete();
      })
    });
  }

  setUser(user): void {
    let ident = user.user;
    let pfl = user.profil;
    if (ident) ident.isAdmin = (ident.roles.indexOf('admin') > -1);
    const tokenVal = this.token.getToken();
    if(!tokenVal) return null;
    this.$userSource.next({user: ident, profil: pfl});
    this.$userBehavior.next({user: ident, profil: pfl});
    this.setStored({user: ident, profil: pfl}, true);
    (<any>window).user = user;
  }

  getUser(): Observable<any> {
    return this.$userSource.asObservable();
  }

  me(): Observable<any> {
    return Observable.create(observer => {
      const tokenVal = this.token.getToken();
      if (!tokenVal) return  observer.complete();
      this.http.get('/api/auth/me').subscribe((data : any) => {
        observer.next({user: data.user, profil: data.profil});
        this.setUser(data);
        observer.complete();
      })
    });
  }

  signOut(): void {
    this.token.signOut();
    sessionStorage.clear();
    localStorage.clear();
    this.$userSource.unsubscribe();
    delete (<any>window).user;
    window.location.reload();
  }

  reinitPassword(mail): Observable<any> {
    return Observable.create(obs => {
      this.http.post(`/api/auth/mdp`, {mail}).subscribe((data: any) => {
        obs.next('Mail envoyé');
        obs.complete();
      }, 
      (err: any) => {
        obs.error(err);
      })
    })
  }

  modifPassword(old, newP, id) : Observable<any> {
    return Observable.create(obs => {
      this.http.post('/api/auth/pwd', {old, newP, id}).subscribe((data: any) => {
        // console.log(data)
        obs.next(data);
        obs.complete();
      }, 
      (err: any) => {
        obs.error(err);
      })
    });
  }

}
