import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToolsService } from '../shared/services/tools/tools.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit, OnDestroy {
  private subscription: Subscription
  public user: any;
  public mailSent: boolean = false;

  public subjectInputNoLogged: any = ['Remarques', 'Demande de RDV', 'Autres']
  public visitorSubmitted: boolean = false;
  visitor = new FormGroup({
    fullname: new FormControl(''),
    forname: new FormControl(''),
    email: new FormControl('', [Validators.required, Validators.email]),
    subject: new FormControl(this.subjectInputNoLogged[2]),
    message: new FormControl('', [Validators.required])
  });

  get visitorFullname(): any {return this.visitor.get('fullname');}
  get visitorForname(): any {return this.visitor.get('forname');}
  get visitorEmail(): any {return this.visitor.get('email');}
  get visitorSubject(): any {return this.visitor.get('subject');}
  get visitorMessage(): any {return this.visitor.get('message');}


  public subjectInput:any = ['Cours', 'Exercices', 'Corrige', 'Examen', 'Disfonctionnement', 'Calendrier', 'Autres'];
  public submitted: boolean = false;
  logged = new FormGroup({
    fullname: new FormControl(''),
    email: new FormControl(''),
    subject: new FormControl(this.subjectInput[6]),
    message: new FormControl('', [Validators.required])
  });

  get fullname(): any {return this.logged.get('fullname');}
  get email(): any {return this.logged.get('email');}
  get subject(): any {return this.logged.get('subject');}
  get message(): any {return this.logged.get('message');}


  constructor(
    private Auth: AuthService,
    private Tool: ToolsService
  ) { }

  ngOnInit() {
    this.subscription = this.Auth.getUserBehavior().subscribe(res => {
      this.user = res;
      if(res) {
        this.logged.patchValue({
          fullname: this.user.user.fullname,
          email: this.user.user.email
        });
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onSubmitVisitor() {
    this.visitorSubmitted = true;
    if(!this.visitor.valid) return null;

    this.visitorSubmitted = false;
    let {
      fullname,
      forname,
      email,
      subject,
      message
    } = this.visitor.getRawValue();

    this.Tool.sendMailVisitor(fullname, forname, email, subject, message)
    .subscribe(res => {
      this.visitor.reset();
      this.mailSent = true;
      setTimeout(() => {
        this.mailSent = false;
      }, 3000);
    });
  }

  onSubmit() {
    this.submitted = true;
    if(!this.logged.valid) return null;

    this.submitted = false;
    let {
      fullname,
      email,
      subject,
      message
    } = this.logged.getRawValue();
    let organism = this.user.profil.organism;

    this.Tool.sendMail(fullname, organism, email, subject, message)
    .subscribe(res => {
      this.logged.patchValue({
        fullname: this.user.user.fullname,
        email: this.user.user.email,
        message: null
      });
      this.mailSent = true;
      setTimeout(() => {
        this.mailSent = false;
      }, 3000);
    })
  }
}
