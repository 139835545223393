import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

const endpoint = '/api/communication';

export class _Advert {
  public static fromJson(json: Object): _Advert {
    return new _Advert(
      json['type'],
      json['promotions'],
      json['text'],
      json['createdOn']
    )
  }

  constructor(
    public _id?: String,
    public type: String = '',
    public promotions: Array<String> = [],
    public text: String = '',
    public createdOn: Number = Date.now()
  ) {}
}

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class AdvertService {

  constructor(
    private http: HttpClient
  ) { }

  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if(err.error instanceof ErrorEvent) {
      errorMessage = `An error occured: ${err.error.message}`;
    } else {
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

  /**
   * Get advert for one prom
   * @param {String} id - user's prom
   * @return {Array<_Advert>}
   */
  getAdvert(id: string) {
    return this.http.get<_Advert[]>(`${endpoint}/advert/${id}`).pipe(
      catchError(this.handleError)
    )
  }
}
